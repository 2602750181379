/**
 * Maskinia - fix counter for images.
 */

import {ReactElement} from 'Type/Common.type';

import 'SourceComponent/Slider/Slider.style';

import {SliderComponent as SourceSliderComponent} from 'SourceComponent/Slider/Slider.component';
import ChevronIcon from "Component/ChevronIcon";
import {Directions} from "Component/ChevronIcon/ChevronIcon.config";

/**
 * Slider component
 * @class Slider
 * @namespace Component/Slider/Component
 */
export class SliderComponent extends SourceSliderComponent {

    goNext(): void {
        const { activeImage, children } = this.props;
        const nextImage = activeImage + 1;
        // @ts-ignore
        const countImages = children[1].length || 0;


        if (nextImage < countImages) {
            this.changeActiveImage(nextImage);
        }
    }

    renderArrows(): ReactElement {
        const { showArrows, activeImage, children } = this.props;
        // @ts-ignore
        const countImages = children[1].length || 0;
        const nextIsDisabled = activeImage + 1 === countImages;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                    block="Slider"
                    elem="Arrow"
                    mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                    aria-label={ __('Previous') }
                    onClick={ this.goPrev }
                >
                    <ChevronIcon direction={ Directions.LEFT } />
                </button>
                <button
                    block="Slider"
                    elem="Arrow"
                    mods={ { isNext: true, isDisabled: nextIsDisabled } }
                    aria-label={ __('Next') }
                    onClick={ this.goNext }
                >
                    <ChevronIcon direction={ Directions.RIGHT } />
                </button>
            </>
        );
    }

    renderCounter(): ReactElement {
        const {children, showCounter, activeImage} = this.props;

        // @ts-ignore
        const countImages = children[1].length || 0;
        if (!showCounter || children.length <= 1) {
            return null;
        }

        return (
            <div
                block="Slider"
                elem="Counter"
            >
                {activeImage + 1}
                /
                {countImages}
            </div>
        );
    }
}

export default SliderComponent;
